<mat-card appearance="outlined" class="vc-card mat-elevation-z5">
  <mat-card-header>
    <div class="title-container flex">
      <h2 class="basis-4/5">Validation</h2>
      <div class="flex grow justify-end align-middle items-center">
        @if (reportingEnabled) {
          <span class="material-symbols-outlined report-icon"
            [routerLink]="['/reporting']" [queryParams]="{report: PAYER_VALIDATION_TREND_REPORT_NAME, year: serviceYear}"
            [matTooltip]="'Generate Report'"
            [matTooltipClass]="'app-tooltip'"
          [matTooltipPosition]="'above'">export_notes</span>
        }

        @if (!reportingEnabled) {
          <span class="material-symbols-outlined report-icon" aria-disabled="true"
            [matTooltip]="'Contact Admin for Access to Generate Report'"
            [matTooltipClass]="'app-tooltip'"
          [matTooltipPosition]="'above'">export_notes</span>
        }
      </div>
    </div>
  </mat-card-header>

  <mat-card-content class="vc-card-summary-grid">
    <mat-grid-list cols="6" rowHeight="40px" gutterSize="0">

      <mat-grid-tile class="header-tile" [colspan]="3">
        <div class="toggle-container grow">
          <mat-slide-toggle color="accent" [(ngModel)]="validationDxSwitch" [hideIcon]="true">
            <span>DX View</span>
          </mat-slide-toggle>
        </div>
      </mat-grid-tile>

      <mat-grid-tile class="header-tile" [colspan]="2">
        <div class="flex grow justify-end align-middle">
          @if (!validationDxSwitch) {
            <h2>HCCs</h2>
          }
          @if (validationDxSwitch) {
            <h2>DXs</h2>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile class="header-tile">
        <div class="flex grow justify-end align-middle">
          <h2>%</h2>
        </div>
      </mat-grid-tile>

      <!--Payor Validation-->
      <mat-grid-tile [colspan]="3">
        <div class="flex grow justify-start align-middle">
          <h3><a [routerLink]="['/diagnosis-event', {qfilter: 'Payer Validation'}]">Payer Validation</a></h3>
        </div>
      </mat-grid-tile>

      <mat-grid-tile [colspan]="2">
        <div class="flex grow justify-end align-middle">
          @if (!validationDxSwitch) {
            <h3>{{hccValue | number}}</h3>
          }
          @if (validationDxSwitch) {
            <h3>{{dxValue | number}}</h3>
          }
        </div>
      </mat-grid-tile>

      <mat-grid-tile>
        <div class="flex grow justify-end align-middle">
          @if (!validationDxSwitch) {
            <h3>{{hccPercent | percent}}</h3>
          }
          @if (validationDxSwitch) {
            <h3>{{dxPercent | percent}}</h3>
          }
        </div>
      </mat-grid-tile>

    </mat-grid-list>

    @if (data.DateUpdated) {
      <label class="data-refresh-label">Data Refreshed {{data.DateUpdated | date: 'MM/dd/YYYY'}}</label>
    }
  </mat-card-content>
</mat-card>