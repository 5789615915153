import {
  getState,
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from "@ngrx/signals";
import { PayerItem } from "../models/payer-item";
import { computed, inject } from "@angular/core";
import { UtcDateTimeFormatter } from "../helpers/utc-datetime-formatter";
import { MorDataService } from "../services/mor/mor-data.service";
import { MorDataTableValuesRequest } from "../models/mor-data-table-values-request";
import { MorDataTableRow } from "../components/sub-components/mor-data/mor-data-table/mor-data-table.component";
import { MorDataTableItem } from "../models/mor-data-table-item";

type MorDataState = {
  selectedPayers: PayerItem[];
  localThresholdDate: Date | null;
  tableData: MorDataTableRow[];
};

const initialState: MorDataState = {
  selectedPayers: [],
  localThresholdDate: null,
  tableData: [],
};

const setPayersHelper = (store, payers?: PayerItem[]): void => {
  if (payers) {
    patchState(store, { selectedPayers: payers });
  }
};

const morDbItemsToTableDataHelper = (results: MorDataTableItem[]) => {
  const rows: MorDataTableRow[] = [];
  for (const item of results) {
    const utcDate = new Date(item.UploadDate + 'Z')
    const row: MorDataTableRow = {
      uploadDate: utcDate,
      payerAlias: item.PayerAlias,
      uploadedBy: item.FirstName.charAt(0) + item.LastName,
      fileName: item.FileName,
      status: item.Status
    };
    rows.push(row);
  }
  return rows;
}

const getSelectedPayerIdsString = (state: MorDataState) => {
    let payerIds: string = "";
    for (const payer of state.selectedPayers) {
      if (payerIds == "") {
          payerIds += `${payer.ID}`;
      } else {
          payerIds += `,${payer.ID}`;
      }
    }
    return payerIds;
}

export const MorDataStore = signalStore(
  withState(initialState),
  withComputed((state) => ({
    formValid: computed(() => {
      return state.selectedPayers().length > 0;
    }),
  })),
  withMethods(
    (
      store,
      utcHelper = inject(UtcDateTimeFormatter),
      service = inject(MorDataService)
    ) => ({
      setSelectedPayers(payers: PayerItem[]) {
        setPayersHelper(store, payers);
      },
      setThresholdDate(date: Date | null) {
        patchState(store, { localThresholdDate: date });
      },
      getTableData() {
        const state = getState(store);
        const query: MorDataTableValuesRequest = {
          payerIds: getSelectedPayerIdsString(state),
          thresholdDateString: state.localThresholdDate
            ? utcHelper.getFormattedUTCString(state.localThresholdDate)
            : "",
        };
        service
          .getMORDataTableItems(query)
          .subscribe((results?: MorDataTableItem[]) => {
            if (results) {
              const rows = morDbItemsToTableDataHelper(results);
              patchState(store, { tableData: rows });
            }
          });
      },
    })
  )
);