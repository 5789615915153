export class MimeTypeConstants {
  static readonly CSV = { mimeType: "text/csv", extension: ".csv" };
  static readonly TXT = { mimeType: "text/plain", extension: ".txt" };
  static readonly XLS = { mimeType: "application/vnd.ms-excel", extension: ".xls" };
  static readonly XLSX = {
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: ".xlsx",
  };

  // Get all constants as an array for dynamic mapping
  static getAll(): { mimeType: string; extension: string }[] {
    return Object.values(MimeTypeConstants);
  }
}

export class MimeTypeHelper {
  private static mimeTypeToExtensionMap: { [key: string]: string } =
    MimeTypeConstants.getAll().reduce((map, { mimeType, extension }) => {
      map[mimeType] = extension;
      return map;
    }, {} as { [key: string]: string });

  static getFileExtension(mimeType: string): string | null {
    return this.mimeTypeToExtensionMap[mimeType] || null;
  }

  static addMimeTypeMapping(mimeType: string, extension: string): void {
    this.mimeTypeToExtensionMap[mimeType] = extension;
  }
}
