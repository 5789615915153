<div class="flex basis-[7%] flex-row">
    <div class="flex flex-auto justify-start items-center content-center">
        <h1>MOR Data</h1>
    </div>
    <div class="flex">
        <button mat-flat-button class="primary-button-longtext my-auto" (click)="handleUploadButtonClick()">+ Upload MOR File</button>
    </div>
</div>

<div class="filter-section grid grid-cols-5 gap-3 p-3">
    <div>
        <app-payer-select
        [form]="form"
        [multiple]="true"
        [selectAllEnabled]="true"
        [queryOptions]="true"
        [required]="true"
        [filterByMORMap]="true"
        (valueChanged)="handlePayerValueChange($event)"></app-payer-select>
    </div>
    <div>
        <app-upload-date
        [form]="form"
        [required]="true"></app-upload-date>
    </div>
    <div class="pt-3">
        <button mat-flat-button class="primary-button"
          [disabled]="!generateEnabled"
          [matTooltip]="generateTooltip"
          [matTooltipClass]="'app-tooltip'"
        (click)="onGenerateReport()">Generate</button>
      </div>
</div>

<div class="grid-section flex-auto">
    <app-mor-data-table></app-mor-data-table>
</div>