class UtcDateTimeFormatterHelper {
    day: string;
    month: string;
    year: string;
    hours: string;
    minutes: string;
    seconds: string;
}

export class UtcDateTimeFormatter {
  getFormattedUTCString(date: Date) {
    return this.formatDateAsUtc(date);
  }

  getUTCStringFormattedForFileName(date: Date) {
    return this.formatDateForFileName(date);
  }

  //Takes in a local datetime and returns a utc string of it formatted 'mm/dd/yyyy'
  private formatDateAsUtc(date: Date): string {
    const utcDateValues = this.getDateTimeValues(date);

    return `${utcDateValues.month}/${utcDateValues.day}/${utcDateValues.year} ${utcDateValues.hours}:${utcDateValues.minutes}:${utcDateValues.seconds}`;
  }

  private formatDateForFileName(date: Date): string {
    const utcDateValues = this.getDateTimeValues(date);

    return `${utcDateValues.year}${utcDateValues.month}${utcDateValues.day}${utcDateValues.hours}${utcDateValues.minutes}${utcDateValues.seconds}_`;
  }

  private getDateTimeValues(date: Date): UtcDateTimeFormatterHelper {
    const result = new UtcDateTimeFormatterHelper();
    result.day = String(date.getUTCDate()).padStart(2, "0");
    result.month = String(date.getUTCMonth() + 1).padStart(2, "0");
    result.year = String(date.getUTCFullYear());

    result.hours = String(date.getUTCHours()).padStart(2, "0");
    result.minutes = String(date.getUTCMinutes()).padStart(2, "0");
    result.seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return result;
  }
}