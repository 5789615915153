import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatSelectSearchComponent, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PayerItem } from '../../../models/payer-item';
import { FieldFilterService } from '../../../services/filters/field-filter.service';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-payer-select',
    templateUrl: './payer-select.component.html',
    styleUrls: ['./payer-select.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatSelect, FormsModule, ReactiveFormsModule, MatTooltip, MatSelectTrigger, MatOption, NgxMatSelectSearchModule, AsyncPipe]
})
export class PayerSelectComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;
  @Input() color = 'primary';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() multiple = true;
  @Input() controlName = 'payer';
  @Input() toolTipPosition = 'above';
  @Input() selectAllEnabled = true;
  @Input() required = false;
  @Input() queryOptions = false;
  @Input() placeholder = "Make a Selection"
  @Input() filterByMORMap = false;

  options: PayerItem[] = [];
  filteredOptions: ReplaySubject<PayerItem[]> = new ReplaySubject<PayerItem[]>(1);
  control = new FormControl(null);
  filterControl = new FormControl<string>('');
  allToggled = false;
  indeterminateToggle = false;

  subs: Subscription[] = [];

  protected _onDestroy = new Subject<void>();

  @Output() ready = new EventEmitter();
  @Output() valueChanged = new EventEmitter();

  @ViewChild(MatSelect) select: MatSelect;
  @ViewChild(MatSelectSearchComponent) searchFilter: MatSelectSearchComponent;

  isReady = signal<boolean>(false);

  constructor(private filterService: FieldFilterService) { 
    //TODO
  }

  ngOnInit(): void {

    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }

    this.filterControl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterMulti();

      if(this.multiple) {
        this.setAllToggleState();
      }
    });

    if(this.multiple) {
      this.control.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.setAllToggleState();
      });
    }

    if(this.queryOptions) {
      this.getOptionData();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  getOptionData() {
    if (this.filterByMORMap) {
      this.filterService.getMORPayerOptions().subscribe(result => {
        this.setOptionData(result);
        this.isReady.set(true);
      });
    } else {
      this.filterService.getPayerOptions().subscribe(result => {
        this.setOptionData(result);
        this.ready.emit();
      })
    }
  }

  setOptionData(payerOptions: PayerItem[]) {
    this.options = payerOptions;
    this.filteredOptions.next(this.options);
  }

  setSelectedValues(values: PayerItem[]) {

    if(!values) {
      this.control?.reset();
      this.control?.setValue([]);
      this.searchFilter._reset();
      return;
    }

    if(values.length == 0) {
      this.allOptionClicked(true);
      return;
    }

    values.forEach(o => {
      const found = this.select.options.find((i, index) => {
        if(index == 0) {
          return;
        }

        return i.value.ID == o.ID;
      });

      if(found) {
        found.select();
      }
    });
  }

  getValues() {

    if(this.allToggled && this.multiple) {
      return [{ID: 0}];
    }
    
    return this.control?.value ? this.control?.value.map(i => i) : [];
  }

  allOptionClicked(isChecked: boolean) {

    this.select.options.forEach((o, index) => {
      if(index == 0) {
        return;
      }

      isChecked ? o.select() : o.deselect();
    });

    this.options.forEach(o => {
      o.Selected = isChecked;
    });

  }

  toggleSelection(option: MatOption, item: PayerItem) {
    item.Selected = option.selected;
  }

  checkAllOptions() {
    let result = true;
    this.options.forEach(o => {
      if(!o.Selected) {
        result = false;
      }
    });

    return result;
  }

  onSelectionChange() {
    this.valueChanged.emit(this.control?.value);
  }

  getTooltipString() {
    let result = '';
    const options = this.control?.value;

    options.forEach((o, index) => {
      result += o.Abbreviation;

      if(index < options.length - 1) {
        result += ', ';
      }
    })
    return result;
  }

  getSelectCount() {
    return (this.control.value?.length <= this.options.length) 
      ? this.control.value?.length 
      : this.control.value?.length - 1;
  }

  filterMulti() {
    if (!this.options) {
      return;
    }

    let search = this.filterControl?.value;

    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredOptions.next(
      this.options.filter(o => (o.Abbreviation.toLowerCase()).indexOf(search.toLowerCase()) > -1)
    );
  }

  setAllToggleState() {
    let filteredLength = 0;
    if(this.control?.value) {
      this.options.forEach(o => {
        if(this.control?.value.indexOf(o) > -1) {
          filteredLength++;
        }
      });

      this.indeterminateToggle = filteredLength > 0 && filteredLength < this.options.length;
      this.allToggled = filteredLength > 0 && filteredLength === this.options.length;
    }
  }

  markAsTouched() {
    this.control.markAsTouched();
  }

}
