import { Component, ViewChild } from '@angular/core';
import { TreeMenuComponent } from '../../sub-components/tree-menu/tree-menu.component';
import { DataRefreshConfigComponent } from '../../sub-components/data-refresh-config/data-refresh-config.component';
import { ListMenuOption } from '../../../models/list-menu-option';
import { MorDataComponent } from '../../sub-components/mor-data/mor-data.component';
import { MorDataStore } from '../../../store/mor-data.store';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-file-management',
  standalone: true,
  imports: [
    TreeMenuComponent,
    DataRefreshConfigComponent,
    MorDataComponent,
  ],
  providers: [
    MorDataStore,
    DatePipe
  ],
  templateUrl: './file-management.component.html',
  styleUrl: './file-management.component.scss'
})
export class FileManagementComponent {
  sideContainerExpanded = false;
  currentPageID = 1;
  pageIDKey = "VC-File-Management-Current-Page";

  fileManagementMenuOptions: ListMenuOption[] = [
    {ID: 1, Name: "Data Refresh Log", Icon: "cached", IconOutlined: false, Disabled: false},
    {ID: 2, Name: "MOR Data", Icon: "description", IconOutlined: false, Disabled: false},
  ];

  selectedOption: ListMenuOption = new ListMenuOption();

  @ViewChild(TreeMenuComponent) treeMenu: TreeMenuComponent;

  constructor() {
    this.currentPageID = JSON.parse(sessionStorage.getItem(this.pageIDKey) || '1');
  }

  ngOnInit(): void {
    //TODO
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.treeMenu.setSelectedOption(this.currentPageID);
    }, 300);
  }

  onSelectedOption(option: ListMenuOption) {
    this.selectedOption = option;
    sessionStorage.setItem(this.pageIDKey, JSON.stringify(option.ID));
  }

}
