import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-view-icon-renderer',
  standalone: true,
  imports: [
    MatIcon,
    MatTooltipModule,
  ],
  templateUrl: './view-icon-renderer.component.html',
  styleUrl: './view-icon-renderer.component.scss'
})
export class ViewIconRendererComponent implements ICellRendererAngularComp {
  private params: ICellRendererParams<any, any>;

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams<any, any>) {
    this.params = params;
    return true;
  }

  onClick(event: any) {
    // TODO: open viewer
  }
}
