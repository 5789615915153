<ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-balham"
    [components]="gridComponents"
    [columnDefs]="gridOptions.columnDefs"
    [statusBar]="gridOptions.statusBar"
    [suppressPaginationPanel]="true"
    [pagination]="true"
    [paginationPageSize]="gridOptions.paginationPageSize"
    [paginationPageSizeSelector]="gridOptions.paginationPageSizeSelector">
</ag-grid-angular>