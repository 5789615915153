import { formatDate, DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel, MatHint, MatSuffix, MatError } from '@angular/material/form-field';
import { MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatDateRangePicker } from '@angular/material/datepicker';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtcDateTimeFormatter } from '../../../helpers/utc-datetime-formatter';

@Component({
    selector: 'app-date-range',
    templateUrl: './date-range.component.html',
    styleUrls: ['./date-range.component.scss'],
    standalone: true,
    imports: [MatFormField, MatLabel, MatDateRangeInput, FormsModule, ReactiveFormsModule, MatStartDate, MatEndDate, MatHint, MatDatepickerToggle, MatSuffix, MatDateRangePicker, MatError, DatePipe],
    providers: [
      UtcDateTimeFormatter,
    ],
})
export class DateRangeComponent implements OnInit, AfterViewInit {

  @Input() form: FormGroup;
  @Input() controlName = 'date-range';
  @Input() required = false;
  @Input() startRequired = false;
  @Input() endRequired = false;
  @Input() appearance = 'outline';
  @Input() label = 'Date Range';
  @Input() startPlaceholder = '';
  @Input() endPlaceholder = '';
  @Input() hint = 'MM/DD/YYYY – MM/DD/YYYY';
  @Input() startDateDecrement = 30;
  @Input() endDateDecrement = 1;
  @Input() endDateDefault = new Date();
  @Input() endDateMax = new Date();
  @Input() startDateMin = new Date(0);

  range: FormGroup;

  @Output() ready = new EventEmitter();
  @ViewChild(MatDateRangeInput) dateRangeInput: MatDateRangeInput<any>;
  valueChanged = signal<{startDate: string | null; endDate: string | null} | null>(null);
  subs: Subscription[] = [];

  readonly utcHelper = inject(UtcDateTimeFormatter);

  constructor() { 
    //TODO
  }

  ngOnInit(): void {
    this.setDefaultState(this.endDateDefault);
    this.ready.emit();
  }

  ngAfterViewInit() {
    this.subs.push(this.dateRangeInput.stateChanges
      .pipe(debounceTime(300))
      .subscribe(() => {
      this.valueChanged.set(this.getRangeAsUTCDateTime());
    }));
  }

  setDefaultState(endDate: Date) {
    if(this.form.get(this.controlName)) {
      this.form.removeControl(this.controlName);
    }

    const startValidators = this.startRequired ? [Validators.required] : [];
    const endValidators = this.endRequired ? [Validators.required] : [];

    const newEndDate = new Date(new Date().setDate(endDate.getDate() - this.endDateDecrement));

    endDate = newEndDate;
    this.endDateMax = newEndDate;
    
    const startDateDefault = endDate ? new Date(new Date().setDate(endDate.getDate() - this.startDateDecrement)) : null;

    this.range = new FormGroup({
      start: new FormControl<Date | null>(startDateDefault, startValidators),
      end: new FormControl<Date | null>(endDate, endValidators),
    });

    this.form.addControl(this.controlName, this.range);
  }

  setEndRangeDecrement(decrement: number) {
    this.endDateDecrement = decrement;

  }

  setStartRangeDecrement(decrement: number): void {
    this.startDateDecrement = decrement;
  }

  getStartDateValue() {
    return formatDate(this.range.value.start, 'MM/dd/yyyy', 'en');
  }

  getEndDateValue() {
    return formatDate(this.range.value.end, 'MM/dd/yyyy', 'en');
  }

  getRangeAsUTCDateTime() {
    return {
      startDate: this.getDateRangeInUTC(this.getStartDateValue()),
      endDate: this.getDateRangeInUTC(this.getEndDateValue(), false)
    }
  }

  private getDateRangeInUTC(date:string, isStartDate:boolean = true) {
    let time;
    if(isStartDate) {
      time = { hours: 0, minutes: 0, seconds: 0 };
    } else {
      time = { hours: 23, minutes: 59, seconds: 59 };
    }

    // Parse the input date string (MM/DD/YYYY)
    const [month, day, year] = date.split('/').map(Number);

    // Create a local time Date object
    const localDate = new Date(year, month - 1, day, time.hours, time.minutes, time.seconds)

    const utcString = this.utcHelper.getFormattedUTCString(localDate);

    return utcString;
  }

}
