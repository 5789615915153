import { ComponentType } from '@angular/cdk/portal';
import { Component, effect, inject, ViewChild, signal } from '@angular/core';
import { FileUploadDropzoneComponent, FileUploadDropzoneModel } from '../../components/field-components/file-upload-dropzone/file-upload-dropzone.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PayerSelectComponent } from '../../components/field-components/payer-select/payer-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MorDataUploadStore } from '../../store/mor-data-upload.store';
import { PayerItem } from '../../models/payer-item';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { DropzoneTrackingComponent } from "../../components/field-components/file-upload-dropzone/dropzone-tracking/dropzone-tracking.component";
import { UtcDateTimeFormatter } from '../../helpers/utc-datetime-formatter';
import { LoadingDialogComponent } from '../loading-dialog/loading-dialog.component';
import { MimeTypeHelper } from '../../helpers/mime-type-helper';

export class MorDataUploadDialogModel {
  readonly component: ComponentType<MorDataUploadDialogComponent> = MorDataUploadDialogComponent;
  panelClass?: string | string[] = 'dialog-container';
  autoFocus?: boolean = false;
  maxWidth? = '95dvw';
  data;
  disableClose? = true;

  constructor(data) {
    this.data = data;
  }
}

@Component({
  selector: 'app-mor-data-upload-dialog',
  standalone: true,
  imports: [
    FileUploadDropzoneComponent,
    PayerSelectComponent,
    MatButtonModule,
    MatTooltipModule,
    MatIcon,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    DropzoneTrackingComponent
],
  providers: [
    MorDataUploadStore,
    UtcDateTimeFormatter,
    MimeTypeHelper,
  ],
  templateUrl: './mor-data-upload-dialog.component.html',
  styleUrl: './mor-data-upload-dialog.component.scss'
})
export class MorDataUploadDialogComponent {
  form: FormGroup;

  @ViewChild(FileUploadDropzoneComponent) morUploadField: FileUploadDropzoneComponent;
  @ViewChild(PayerSelectComponent) payerSelect: PayerSelectComponent;
  @ViewChild(DropzoneTrackingComponent) trackingComponent: DropzoneTrackingComponent;

  formBuilder = inject(FormBuilder);
  dropzoneModel: FileUploadDropzoneModel;
  payerTooltip = "Contains a listing of all payers that have a configured MOR file mapping. If payer is not listed, contact ValueCycle Customer Service.";
  dropzoneTooltip = signal<string>("");
  subs: Subscription[] = [];

  readonly store = inject(MorDataUploadStore);
  readonly data = inject(MAT_DIALOG_DATA);
  uploadError: string | null;

  constructor(
    public dialogRef: MatDialogRef<MorDataUploadDialogComponent>,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
  ) {
    this.form = this.formBuilder.group({});
    this.dropzoneModel = this.data.item;
    effect(() => {
      this.morUploadField.disabled = this.store.currentFile() != null;
    });
    effect(() => {
      this.uploadError = this.morUploadField.invalidReason();
    });

    const validFileTypeString = this.data.item.validMimeTypes
          .map((type) => MimeTypeHelper.getFileExtension(type) || type) // Replace MIME types with extensions or fallback to MIME type
          .join(", ")
          .replace(/, ([^,]*)$/, ", and $1");
    
    this.dropzoneTooltip.set(`Accepted file types: ${validFileTypeString}\n\nTo upload a file, please ensure it is under the maximum allowed size of 200 MB`);
  }

  handlePayerSelected(event: PayerItem) {
    if (event) {
      this.store.setSelectedPayer(event);
    }
  }

  handleFileDropped(event: File[]) {
    if (event) {
      this.trackingComponent.addFiles(event);
      this.store.setCurrentFile(event[0]); 
    }
    if (!this.store.selectedPayer()) {
      this.payerSelect.markAsTouched();
    }
  }

  handleFileDeleted(event: File[]) {
    if (event) {
      this.store.setCurrentFile(null);
    }
  }

  handleUploadSubmit() {
    const spinner = this.dialog.open(LoadingDialogComponent, 
      {
        disableClose: true,
        panelClass: 'custom-spinner-dialog'
      });
    this.store.uploadCurrentFile().then((result) => {
      if (result) {
        spinner.close();
        this.dialogRef.close(true);
      } else {
        spinner.close();
      }
    });
  }

  handleCancel() {
    if (this.store.currentFile()) {
      const message = 'Clicking Cancel will not save the file selected for upload. ' 
        + 'Do you want to continue to close and not save?';
      const title = 'Warning';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        maxWidth: "450px",
        data: new ConfirmDialogModel(title, message, '')
      });

      this.subs.push(dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.store.resetState();
          this.dialogRef.close(false);
        }
      }));
    } else {
      this.dialogRef.close(false);
    }
  }
}
