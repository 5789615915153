import { Component, inject, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef, effect, OnDestroy, signal, computed } from '@angular/core';
import { FileUploadDropzoneModel } from "../../field-components/file-upload-dropzone/file-upload-dropzone.component";
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MorDataUploadDialogComponent, MorDataUploadDialogModel } from "../../../dialogs/mor-data-upload-dialog/mor-data-upload-dialog.component";
import { MatButtonModule } from '@angular/material/button';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PayerSelectComponent } from "../../field-components/payer-select/payer-select.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MorDataStore } from '../../../store/mor-data.store';
import { PayerItem } from '../../../models/payer-item';
import { UploadDateComponent } from '../../field-components/upload-date/upload-date.component';
import { UtcDateTimeFormatter } from '../../../helpers/utc-datetime-formatter';
import { MorDataTableComponent } from './mor-data-table/mor-data-table.component';
import { MimeTypeConstants } from '../../../helpers/mime-type-helper';

@Component({
  selector: 'app-mor-data',
  standalone: true,
  imports: [
    MatButtonModule,
    UploadDateComponent,
    PayerSelectComponent,
    MatTooltipModule,
    MorDataTableComponent,
],
  providers: [
    MorDataStore,
    UtcDateTimeFormatter
  ],
  templateUrl: './mor-data.component.html',
  styleUrl: './mor-data.component.scss'
})
export class MorDataComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  generateEnabled: boolean = false;

  uploadDialogDropzoneLabel = "Drag & Drop to Add File for Upload OR";
  uploadDialogBrowseButtonLabel = "Browse for File";
  generateTooltip = 'Generate list of Uploaded MORs';
  subs: Subscription[] = [];

  @ViewChild(UploadDateComponent) uploadDateComp: UploadDateComponent;
  @ViewChild(PayerSelectComponent) payerSelectComp: PayerSelectComponent;

  readonly store = inject(MorDataStore);
  controlsValid: string;

  uploadDateReady = signal<boolean >(false);
  payerSelectReady = signal<boolean>(false);

  // Computed signal that has a value of true if and only if both grid filters are ready
  pageReady = computed<boolean>(() => this.uploadDateReady() && this.payerSelectReady());

  // Used to prevent circular logic on initial report generation
  initalLoadComplete = signal<boolean>(false);

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.form = this.fb.group({});
    effect(() => {
      this.generateEnabled = this.store.formValid();
    });
    effect(() => {
      this.uploadDateReady.set(this.uploadDateComp.ready());
    }, {allowSignalWrites: true});
    effect(() => {
      this.payerSelectReady.set(this.payerSelectComp.isReady());
    }, {allowSignalWrites: true})
    effect(() => {
      if (this.payerSelectReady()) {
        this.onPayersFieldReady();
      }
    }, { allowSignalWrites: true });
    effect(() => {
      if (this.pageReady() && !this.initalLoadComplete()) {
        this.onGenerateReport();
        this.initalLoadComplete.set(true);
      }
    }, { allowSignalWrites: true });
    effect(() => {
      this.store.setThresholdDate(
        this.uploadDateComp.valueChanged()
      );
    }, { allowSignalWrites: true });
  }

  ngOnInit() {
    this.subs.push(this.form.statusChanges.subscribe((s) => {
      this.changeDetector.detectChanges();
      this.controlsValid = s;
    }));
  }

  ngAfterViewInit() {
    //TODO
  }

  handleUploadSubmitSuccess() { 
    setTimeout(() => {
      this.onGenerateReport();
    }, 200);
  }

  handleUploadButtonClick() {
    const dropzoneModel = new FileUploadDropzoneModel(
      false, 
      false, 
      this.uploadDialogDropzoneLabel, 
      this.uploadDialogBrowseButtonLabel,
      [
        MimeTypeConstants.CSV.mimeType, 
        MimeTypeConstants.XLS.mimeType, 
        MimeTypeConstants.XLSX.mimeType,
        MimeTypeConstants.TXT.mimeType, 
      ]
    );
    const data = { item: dropzoneModel }

    const dialogRef = this.dialog.open(MorDataUploadDialogComponent, new MorDataUploadDialogModel(data));

    this.subs.push(dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.handleUploadSubmitSuccess();
      }
    }));
  }

  onPayersFieldReady() {
    setTimeout(() => {
      this.payerSelectComp.setSelectedValues([]);
    }, 200);

    // All selected by default, so set Payers list in state to all options.
    this.store.setSelectedPayers(this.payerSelectComp.options);
  }

  handlePayerValueChange(event: PayerItem[]) {
    this.store.setSelectedPayers(event);
  }

  onGenerateReport() {
    this.store.getTableData();
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => { sub.unsubscribe() });
  }
}
