import { Component, EventEmitter, Input, Output, signal, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ClientToastController } from '../../../toasts/client-toast.lib';
import { DropzoneTrackingComponent } from './dropzone-tracking/dropzone-tracking.component';
import { MimeTypeHelper } from '../../../helpers/mime-type-helper';
import { CommonModule } from '@angular/common';

export class FileUploadDropzoneModel {
  multiple: boolean = true;
  trackUploads: boolean = true;
  dropzoneLabel: string = "";
  browseButtonLabel: string = "";
  validMimeTypes: string[] = [];

  constructor(
    multiple?: boolean,
    trackUploads?: boolean,
    dropzoneLabel?: string,
    browseButtonLabel?: string,
    validMimeTypes?: string[]
  ) {
    this.multiple = multiple ?? this.multiple;
    this.trackUploads = trackUploads ?? this.trackUploads;
    this.dropzoneLabel = dropzoneLabel ?? this.dropzoneLabel;
    this.browseButtonLabel = browseButtonLabel ?? this.browseButtonLabel;
    if (validMimeTypes && validMimeTypes.length > 0) {
      for (const fileType of validMimeTypes) {
        this.validMimeTypes.push(fileType);
      }
    }
  }
}

class FileValidationHelper {
  index: number;
  valid: boolean;
  reason: string;
}

@Component({
  selector: "app-file-upload-dropzone",
  standalone: true,
  imports: [MatButtonModule, DropzoneTrackingComponent, CommonModule],
  providers: [MimeTypeHelper],
  templateUrl: "./file-upload-dropzone.component.html",
  styleUrl: "./file-upload-dropzone.component.scss",
})
export class FileUploadDropzoneComponent {
  @Input() model: FileUploadDropzoneModel;
  @Output() filesDropped = new EventEmitter<File[]>();
  @Output() filesDeleted = new EventEmitter<File[]>();

  invalidReason = signal<string | null>(null);
  
  @ViewChild(DropzoneTrackingComponent) tracking: DropzoneTrackingComponent;
  disabled: boolean = false;
  private readonly TOAST_DURATION_MS = 3000;

  constructor(private toastController: ClientToastController) {}

  ngOnInit() {
    //TODO
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;

    if (input.files) {
      this.processFiles(input.files, (message) => {
        this.invalidReason.set(message);
      });
      input.value = ""; // Clear input after processing
    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer?.files && !this.disabled) {
      this.processFiles(event.dataTransfer.files, (message) => {
        this.invalidReason.set(message);
      });
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.classList.add("dragover");
    }
  }

  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (event.currentTarget instanceof HTMLElement) {
      event.currentTarget.classList.remove("dragover");
    }
  }

  handleDeleteFromTracking(event: File[]) {
    this.filesDeleted.emit(event);
  }

  reset() {
    if (this.model.trackUploads) {
      this.tracking.files = [];
      this.tracking.progressBars = [];
    }
  }

  private addFiles(newFiles: File[]): void {
    if (this.model.trackUploads) {
      this.tracking.addFiles(newFiles);
    }
    this.filesDropped.emit(newFiles);
  }

  private validateFileList(
    files: FileList,
    maxFileSize?: number
  ): FileValidationHelper[] {
    const filesArray = Array.from(files);
    const validMimeTypes = this.model.validMimeTypes;
    const maxBytes = maxFileSize ?? 200 * 1024 * 1024; // 200 MB in bytes (default max)

    return filesArray.map((file, index) => {
      const isFileTypeValid = validMimeTypes.some((type) => file.type === type);
      const isFileSizeValid = file.size < maxBytes;

      if (!isFileTypeValid) {
        return {
          index: index,
          valid: false,
          reason: `Invalid File Type`,
        } as FileValidationHelper;
      }

      if (!isFileSizeValid) {
        return {
          index: index,
          valid: false,
          reason: `File exceeds ${
            maxBytes / 1_048_576 // converts bytes to MB
          } MB max`,
        } as FileValidationHelper;
      }

      return {
        index: index,
        valid: true,
        reason: "", // No issues
      } as FileValidationHelper;
    });
  }

  private processFiles(
    files: FileList,
    respond: (message: string | null) => void
  ): void {
    const fileArray = Array.from(files);

    // Check if multiple files are allowed
    if (!this.model.multiple && fileArray.length > 1) {
      respond("Error on upload: Only one file may be uploaded at a time.");
      return;
    }

    // Validate files if valid file types are specified
    if (this.model.validMimeTypes.length > 0) {
      const validationResults = this.validateFileList(files);

      const invalidResult = validationResults.find((result) => !result.valid);
      if (invalidResult) {
        respond(invalidResult.reason);
        return;
      }
    }
    // Add valid files
    this.addFiles(fileArray);
    respond(null);
  }
}
