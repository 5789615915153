import { Component, EventEmitter, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ClientToastController } from '../../../../toasts/client-toast.lib';

@Component({
  selector: 'app-dropzone-tracking',
  standalone: true,
  imports: [
    MatIcon,
    MatProgressBar,
  ],
  templateUrl: './dropzone-tracking.component.html',
  styleUrl: './dropzone-tracking.component.scss'
})
export class DropzoneTrackingComponent {
  @Output() filesDeleted = new EventEmitter<File[]>();
  files: File[] = [];
  progressBars: number[] = [];

  constructor(
    private toastController: ClientToastController,
  ) {}

  addFiles(newFiles: File[]) {
    try {
      newFiles.forEach((file: File) => {
        this.files.push(file);
        this.progressBars.push(0);
        this.incrementProgressBar(this.progressBars.length - 1, (message: string) => {
          if (newFiles[newFiles.length - 1] == file) {
            this.toastController.displayToast(message, 3000);
          }
        });
      });
    } catch (err) {
      this.toastController.displayToast(`Error: ${err}`, 3000);
    }
  }

  incrementProgressBar(index: number, callback) {
    const interval = setInterval(() => {
      while (this.progressBars[index] < 100) {
        this.progressBars[index] += 10;
      }
      if (this.progressBars[index] == 100) {
        clearInterval(interval);
        callback("File(s) added for upload.");
      }
    }, 1000);
  }

  deleteFile(index: number) {
    const deletedFile = this.files[index];
    this.files.splice(index, 1);
    this.progressBars.splice(index, 1);
    this.filesDeleted.emit([deletedFile]);
  }

  private formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }
}
