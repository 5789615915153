import { getState, patchState, signalStore, withComputed, withMethods, withState } from "@ngrx/signals";
import { PayerItem } from "../models/payer-item";
import { computed, inject } from "@angular/core";
import { MorDataService } from "../services/mor/mor-data.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UtcDateTimeFormatter } from "../helpers/utc-datetime-formatter";
import { HttpEventType } from "@angular/common/http";

type MorDataUploadState = {
    currentFile: File | null;
    selectedPayer: PayerItem | null;
}

const initialState: MorDataUploadState = {
    currentFile: null,
    selectedPayer: null,
}

export const MorDataUploadStore = signalStore(
    withState(initialState),
    withComputed((state) => ({
        formValid: computed(() => state.currentFile() != null && state.selectedPayer() != null),
    })),
    withMethods((
        store, 
        morService = inject(MorDataService), 
        snackbar = inject(MatSnackBar), 
        dateFormatter = inject(UtcDateTimeFormatter),
    ) => ({
        setCurrentFile(input: File | null) {
            patchState(store, { currentFile: input });
        },
        setSelectedPayer(input: PayerItem) {
            patchState(store, { selectedPayer: input });
        },
        async uploadCurrentFile() {
            const state = getState(store);
            if (!state.currentFile || !state.selectedPayer) return;

            const formData = new FormData();
            const fileId = dateFormatter.getUTCStringFormattedForFileName(new Date()) + state.currentFile.name;
            
            formData.append('file', state.currentFile, fileId);
            formData.append('payerAlias', state.selectedPayer.Abbreviation);
            formData.append('payerIdString', state.selectedPayer.ID.toString());          

            try {
                const event = await morService.uploadMORFile(formData).toPromise();
                if (event.type === HttpEventType.Response) {
                    patchState(store, 
                        {
                            currentFile: null,
                            selectedPayer: null,
                        });
                    snackbar.open(`File successfully uploaded!`, undefined, {duration: 3000});
                    return true;
                }
            } catch (error) {
                snackbar.open(`Failed to upload.`, undefined, {duration: 3000});
                return false;
            }
        },
        resetState() {
            patchState(store, initialState);
        },
    })),
);