<div class="flex-1">
  <div 
    class="flex-1 w-80 dropzone" 
    [ngClass]="{ 'dropzone-error': invalidReason() != null }"
    (drop)="onDrop($event)" 
    (dragover)="onDragOver($event)" 
    (dragleave)="onDragLeave($event)">
    <div><div>{{ model.dropzoneLabel }}</div><button mat-flat-button type="button" [disabled]="disabled" (click)="fileInput.click()">{{ model.browseButtonLabel }}</button></div>
    <input 
      type="file" 
      #fileInput 
      [multiple]="model.multiple"
      [disabled]="disabled"
      (change)="onFileSelected($event)" 
      style="display: none;">
  </div>
</div>

@if (model.trackUploads) {
  <div>
    <app-dropzone-tracking
    (filesDeleted)="handleDeleteFromTracking($event)"
    ></app-dropzone-tracking>
  </div>
}