<h1 mat-dialog-title>Upload MOR File</h1>

<div mat-dialog-content>
    <div>
        <div class="flex flex-row">
            <div class="w-52 self-start"><h2>Payer *</h2></div>
            <div class="flex flex-col" style="width: 22rem">
                <div class="flex flex-row">
                    <div class="">
                        <app-payer-select
                        [form]="form"
                        [floatLabel]="'auto'"
                        [multiple]="false"
                        [selectAllEnabled]="false"
                        [required]="true"
                        [queryOptions]="true"
                        [filterByMORMap]="true"
                        (valueChanged)="handlePayerSelected($event)"></app-payer-select>
                    </div>
                    <div class="w-1"></div>
                    <div class="">
                        <mat-icon
                        class="material-icons-outlined"
                        style="font-size: 15px; height: auto;"
                        [matTooltip]="payerTooltip"
                        [matTooltipClass]="'app-tooltip'">info</mat-icon>
                    </div>
                </div>
                <div class="errorMessage align-top min-h-6 text-xs">
                    @if (payerSelect?.control?.touched && payerSelect?.control?.invalid) {
                        Selection is <b>required</b>.
                    }
                </div>
            </div>
        </div>
        <div class="flex flex-row">
            <div class="w-52 self-start"><h2>Upload *</h2></div>
            <div class="flex flex-col" style="width: 22rem">
                <div class="flex flex-row">
                    <div class="">
                        <app-file-upload-dropzone
                        [model]="dropzoneModel"
                        (filesDropped)="handleFileDropped($event)"></app-file-upload-dropzone>
                    </div>
                    <div class="w-1"></div>
                    <div class="">
                        <mat-icon
                        class="material-icons-outlined"
                        style="font-size: 15px; height: auto;"
                        [matTooltip]="dropzoneTooltip()"
                        [matTooltipClass]="['app-tooltip', 'dropzone-tooltip']">info</mat-icon>
                    </div>
                </div>
                <div class="errorMessage align-top min-h-6 max-w-72 text-xs text-center"
                style="margin-top: 0.5rem">
                    @if (uploadError) {
                        {{uploadError}}
                    }
                </div>
            </div> 
        </div>
        <div class="flex flex-row min-h-28">
            <div class="w-52 self-start"><h2>Upload Status</h2></div>
            <app-dropzone-tracking
            (filesDeleted)="handleFileDeleted($event)"></app-dropzone-tracking>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <div class="flex flex-row gap-2">
        <button mat-flat-button class="secondary-button" (click)="handleCancel()">Cancel</button>
        <div class="">
            <button 
            class="submitButton primary-button"
            mat-flat-button type="button" 
            [disabled]="!store.formValid()" 
            (click)="handleUploadSubmit()">Upload</button>
        </div>
    </div>
</mat-dialog-actions>