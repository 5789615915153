import { Component, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    styleUrls: ['./loading-dialog.component.css'],
    standalone: true,
    imports: [MatProgressSpinner]
})
export class LoadingDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
