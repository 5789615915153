import { Component, Input, signal, AfterViewInit, OnInit, inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { UtcDateTimeFormatter } from '../../../helpers/utc-datetime-formatter';

@Component({
  selector: 'app-upload-date',
  standalone: true,
  imports: [
    MatFormField,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  providers: [
    UtcDateTimeFormatter
  ],
  templateUrl: './upload-date.component.html',
  styleUrl: './upload-date.component.scss'
})
export class UploadDateComponent implements AfterViewInit, OnInit {
  @Input() form: FormGroup;
  @Input() required: boolean = false;
  @Input() controlName: string = 'upload-date';
  @Input() appearance = 'outline';
  @Input() floatLabel = 'always';
  @Input() color = 'primary';
  @Input() initialValue = 60;

  control = new FormControl<number | null>(60);

  filterOptions = [
    { label: 'Last 30 Days', value: 30 },
    { label: 'Last 60 Days', value: 60 },
    { label: 'Last 90 Days', value: 90 },
    { label: 'Last 121 Days', value: 121 },
    { label: 'Last 150+ Days', value: 0 }
  ];

  valueChanged = signal<Date | null>(null);
  ready = signal<boolean>(false);

  readonly utcHelper = inject(UtcDateTimeFormatter);

  @ViewChild(MatSelect) matSelect: MatSelect;

  constructor() {}

  ngOnInit() {
    if(this.required) {
      this.control.setValidators([Validators.required]);
    }

    if(this.form) {
      this.form.addControl(this.controlName, this.control);
    }
  }

  ngAfterViewInit() {
    this.onChange({
      source: this.matSelect,
      value: this.initialValue,
    });

    this.ready.set(true);
  }

  onChange(event: MatSelectChange) {
    // The current local date/time.
    const currentLocalDate = new Date();
    
    if (event.value && event.value != 0) {

      // Calculate a threshold date based on the user's input (`event.value`).
      // 1. `currentLocalDate.getTime()` retrieves the current date and time in milliseconds.
      // 2. `event.value` represents the number of days to subtract, provided when the user selects a filter option.
      // 3. `24 * 60 * 60 * 1000` converts 1 day into milliseconds.
      // 4. Subtracting the calculated milliseconds (`event.value` days) from `currentLocalDate` gives the new threshold date.
      // The resulting `localThresholdDate` represents the date/time that is `event.value` days before `currentLocalDate`.
      const localThresholdDate = new Date(currentLocalDate.getTime() - event.value * 24 * 60 * 60 * 1000);
      this.valueChanged.set(localThresholdDate);
    } else {
      this.valueChanged.set(null);
    }
  }
}
