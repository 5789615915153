<mat-form-field [appearance]="appearance" [floatLabel]="floatLabel" [color]="color">
    <mat-label>Upload Date</mat-label>
    <mat-select 
    (selectionChange)="onChange($event)" 
    [multiple]="false"
    [required]="required"
    [formControl]="control">
        @for (option of filterOptions; track option.label) {
            <mat-option [value]="option.value">
                {{ option.label }}
            </mat-option>
        }
    </mat-select>
</mat-form-field>